import React, { Component, useContext } from 'react';
import ReactModal from 'react-modal';
import Select from 'react-select';
import userService from '../../services/user.service';
import { AppContext } from '../../AppContext';

export default class AddTransaction extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false,
            banks: [{}],

            selectedBank: 'tinkoff',
            amount: '',
            doNotAddTransaction: false,
            comment: ''
        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleAddTransaction = this.handleAddTransaction.bind(this);
    }

    handleAddTransaction(setTransactions, setBanks) {
        const transactionData = {
            bank: this.state.selectedBank,
            amount: Number(this.state.amount),
            dont_add_transaction: this.state.doNotAddTransaction,
            comment: this.state.comment,
        };

        userService.addTransaction(transactionData).then((value) => {
            userService.getTransactions(6).then((response) => {
                setTransactions(response.data.data)
                userService.getBanks().then((banksNew) => {
                    setBanks(banksNew.data.data)
                })
            })
            this.handleCloseModal()
        })
    }

    componentDidMount() {
        this.fetchBanksData()
    }

    handleOpenModal() {
        this.setState({
            showModal: true,
            selectedBank: 'tinkoff',
            amount: '',
            doNotAddTransaction: false,
            comment: ''
        });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    handleSelectChange = (selectedOption) => {
        this.setState({ selectedBank: selectedOption.value });
    };

    handleAmountChange = (event) => {
        this.setState({ amount: event.target.value });
    };

    handleCheckboxChange = (event) => {
        this.setState({ doNotAddTransaction: event.target.checked });
    };

    handleCommentChange = (event) => {
        this.setState({ comment: event.target.value });
    };

    fetchBanksData = async () => {
        try {
            const response = await userService.getBanks(); // здесь нужно получать из AppContext.banks
            let banks = []
            response.data.data.forEach(bank => {
                banks.push({
                    value: bank.title, default: true, label:
                        <div className="flex items-center justify-between p-1">
                            <div className="flex items-center justify-normal">
                                <img src={`images/${bank.title}.png`} className="w-5 h-5 rounded-md"></img>
                                <span className="pl-1.5 text-sm font-medium">{bank.title.charAt(0).toUpperCase() + bank.title.slice(1)}</span>
                                {bank.wallet.length > 10 ? <span className="pl-1.5 text-extra-low font-normal text-dark-gray">**** {bank.wallet.substr(bank.wallet.length - 4)}</span> : ""}
                            </div>
                            <span className="pl-1.5 font-bold text-base text-dark-blue block">{bank.balance.toLocaleString()} ₽</span>
                        </div>
                })
            });
            
            this.setState({ banks: banks });
        } catch (error) {
            console.error('Error fetching weather:', error);
        }
    };

    customStylesModal = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    render() {
        return (
            <AppContext.Consumer>
                {({ showMoney, setShowMoney, banks, setBanks, transactions, setTransactions }) => (
                    <div>
                        <button className="bg-light-blue w-full h-10 block text-white font-medium rounded-lg mt-2" onClick={this.handleOpenModal}>Добавить транзакцию</button>

                        <ReactModal
                            isOpen={this.state.showModal}
                            contentLabel="Minimal Modal Example"
                            style={this.customStylesModal}
                            overlayClassName="overlay__modal"
                            onRequestClose={this.handleCloseModal}
                            shouldCloseOnOverlayClick={true}
                            ariaHideApp={false}
                        >
                            <div className="w-96">
                                <div className="flex justify-between items-center">
                                    <span className="text-base font-semibold">Добавление дохода/расхода</span>
                                    <span onClick={this.handleCloseModal}>
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19 6.91L17.59 5.5L12 11.09L6.41 5.5L5 6.91L10.59 12.5L5 18.09L6.41 19.5L12 13.91L17.59 19.5L19 18.09L13.41 12.5L19 6.91Z" fill="#CCCCCC" />
                                        </svg>
                                    </span>
                                </div>
                                <div className="flex justify-between mt-3 items-center">
                                    <span className="text-base">Счет:</span>
                                    <Select options={this.state.banks} defaultValue={this.state.banks[0]} onChange={this.handleSelectChange}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderRadius: '8px',
                                            }),
                                        }} />
                                </div>
                                <div className="flex justify-between mt-3">
                                    <span className="text-sm">Не добавлять в транзакции</span>
                                    <input type="checkbox" className="custom-checkbox" id="dont_add_transaction" onChange={this.handleCheckboxChange}></input>
                                    <label htmlFor="dont_add_transaction"></label>
                                </div>
                                <div className="mt-3">
                                    <input type="text" className="h-12 w-full bg-dark-white focus:outline-none rounded-lg px-5" placeholder="Введите сумму" onChange={this.handleAmountChange}></input>
                                    <textarea className="h-24 w-full bg-dark-white focus:outline-none rounded-lg px-5 pt-3 mt-3" placeholder="Примечание" onChange={this.handleCommentChange}></textarea>
                                </div>
                                <div className="mt-3 grid grid-cols-2 gap-2">
                                    <button className="bg-light-blue w-full h-10 block text-white rounded-lg" onClick={() => this.handleAddTransaction(setTransactions, setBanks)}>Добавить</button>
                                    <button className="bg-light-gray w-full h-10 block text-white rounded-lg" onClick={this.handleCloseModal}>Отмена</button>
                                </div>
                            </div>
                        </ReactModal>
                    </div>
                )}
            </AppContext.Consumer>
        )
    }
}
