import React, { useEffect, useState } from 'react'
import userService from '../../services/user.service'

export default function Weather() {
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [weatherData, setWeatherData] = useState([]);
  const [lightData, setLightData] = useState("");

  const fetchWeather = async (loader) => {
    if (loader) {
      setWeatherData([])
      setLightData("")
    }

    try {
      const response = await userService.getLight();
      setLightData(response.data.data);
    } catch (error) {
      console.error('Error fetching light:', error);
    }

    try {
      const response = await userService.getWeather();
      setWeatherData(response.data.data);
      setLastUpdate(new Date());
    } catch (error) {
      console.error('Error fetching weather:', error);
    }
  };

  useEffect(() => {
    fetchWeather(true);

    // const interval = setInterval(() => fetchWeather(false), 30 * 60 * 1000);
    //
    // return () => clearInterval(interval);
  }, []);

  if (!weatherData.fact) {
    return (
      <div className="relative">
        <span className="text-lg font-medium mb-4 block text-gray-black">Погода</span>
        <div className="relative overflow-hidden rounded-2xl bg-white">
          <img className="w-20 m-auto py-5" src="https://otkritkis.com/wp-content/uploads/2022/07/gufdh.gif" alt="loader"></img>
        </div>
      </div>
    )
  }
  return (
    <div className="relative ">
      <span className="text-lg font-medium mb-4 block text-gray-black"></span>

      <span className="flex justify-left">
        <span className="text-lg font-medium mb-4 block text-gray-black">Погода</span>

        <svg className="w-5 h-5 mt-1 ml-2" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => fetchWeather(true)}>
          <path d="M1.5 9C1.5 12.7275 4.5225 15.75 8.25 15.75C10.0425 15.75 11.76 15.045 13.05 13.8L11.925 12.675C10.9725 13.6875 9.645 14.25 8.25 14.25C3.57 14.25 1.23 8.595 4.5375 5.2875C7.845 1.98 13.5 4.3275 13.5 9H11.25L14.25 12H14.325L17.25 9H15C15 5.2725 11.9775 2.25 8.25 2.25C4.5225 2.25 1.5 5.2725 1.5 9Z" fill="#329DFF" />
        </svg>
      </span>

      <div className="relative overflow-hidden rounded-2xl bg-white  w-full">
        <div className="absolute rounded-full bg-[#F4CE26] h-24 w-24 blur-[48px]"></div>
        <div className="p-5">
          <div className="flex justify-between">
            <div className="bg-white rounded-md h-10 w-10 m-2 p-1 relative">
              <img src={`https://yastatic.net/weather/i/icons/confident/dark/svg/${weatherData ? weatherData.fact.icon : ''}.svg`} alt="icon-weather"></img>
            </div>
            <div className="text-right">
              <span className="text-3xl font-bold block">{weatherData ? weatherData.fact.feels_like : ""} °C</span>
              <span className="text-low font-normal text-light-gray">
                {lastUpdate.getDate()} {lastUpdate.toLocaleString('default', { 'month': 'long' }).charAt(0).toUpperCase() + lastUpdate.toLocaleString('default', { 'month': 'long' }).slice(1)}, {lastUpdate.getHours() < 10 ? "0" + lastUpdate.getHours() : lastUpdate.getHours()}:{lastUpdate.getMinutes() < 10 ? "0" + lastUpdate.getMinutes() : lastUpdate.getMinutes()}</span>
            </div>
          </div>
          <div className="flex justify-between pt-2 relative">
            <div>
              <span className="block text-light-gray text-sm">Ветер</span>
              <span className="font-bold text-sm">{weatherData ? weatherData.fact.wind_speed : ""} м/с</span>
            </div>
            <div>
              <span className="block text-light-gray text-sm">Влаж.</span>
              <span className="font-bold text-sm">{weatherData ? weatherData.fact.humidity : ""}%</span>
            </div>
            <div>
              <span className="block text-light-gray text-sm">Давл.</span>
              <span
                  className={`font-bold text-sm ${weatherData.fact.pressure_mm > 750 || weatherData.fact.pressure_mm < 746 ? "text-red-500" : ""}`}>{weatherData ? weatherData.fact.pressure_mm : ""}</span>
            </div>
            {/* <div>
              <span className="block text-light-gray text-sm">Свет</span>
              <span className="font-bold text-sm">{lightData != "" ? lightData : ""}</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
