import React, { useRef, useEffect, useState, useContext } from 'react';
import userService from '../../services/user.service';
import { AppContext } from '../../AppContext';

export default function StatMonth() {
  const [data, setData] = useState({
      data: [],
  });
  const { showMoney, setShowMoney } = useContext(AppContext);
  const { banks, setBanks } = useContext(AppContext);

  const fetchData = async () => {
    try {
        // Замените URL_API на фактический URL вашего API
        const response = await userService.getStatMonth();
        const data = response.data.data;
        data.outcome = data.outcome * -1
        setData(data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
      fetchData()
  }, [banks]);

  return (
    // md:max-2xl
    <div className="relative mt-4">
      <span className="text-lg font-medium mb-4 block text-gray-black">Сводка за месяц</span>
      <div className="relative overflow-hidden rounded-2xl bg-white">
        <div className="p-5 grid md:2xl:grid-cols-2">
          <div className="flex">
            <div className="w-12 h-12">
              <svg viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.205322" y="0.5" width="46" height="46" rx="10" fill="#2496FF" />
                <path d="M23.9124 13.6679C23.5219 13.2774 22.8887 13.2774 22.4982 13.6679L16.1343 20.0319C15.7437 20.4224 15.7437 21.0555 16.1343 21.4461C16.5248 21.8366 17.1579 21.8366 17.5485 21.4461L23.2053 15.7892L28.8622 21.4461C29.2527 21.8366 29.8859 21.8366 30.2764 21.4461C30.6669 21.0555 30.6669 20.4224 30.2764 20.0319L23.9124 13.6679ZM24.2053 34.625L24.2053 14.375L22.2053 14.375L22.2053 34.625L24.2053 34.625Z" fill="white" />
              </svg>
            </div>
            <div className="flex flex-col align-middle justify-center ml-2">
              <span className="block text-sm text-light-gray">Доходы</span>
              <span className={`block text-base font-semibold ${showMoney ? "" : "blur-sm"}`}>{data.income ? data.income.toLocaleString() : 0} ₽</span>
            </div>
          </div>
          <div className="flex">
            <div className="w-12 h-12">
              <svg viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.670898" y="0.5" width="46" height="46" rx="10" fill="#ED6A5E" />
                <path d="M22.9638 34.3321C23.3543 34.7226 23.9875 34.7226 24.378 34.3321L30.742 27.9681C31.1325 27.5776 31.1325 26.9445 30.742 26.5539C30.3514 26.1634 29.7183 26.1634 29.3278 26.5539L23.6709 32.2108L18.014 26.5539C17.6235 26.1634 16.9904 26.1634 16.5998 26.5539C16.2093 26.9445 16.2093 27.5776 16.5998 27.9681L22.9638 34.3321ZM22.6709 13.375L22.6709 33.625L24.6709 33.625L24.6709 13.375L22.6709 13.375Z" fill="white" />
              </svg>
            </div>
            <div className="flex flex-col align-middle justify-center ml-2">
              <span className="block text-sm text-light-gray">Расходы</span>
              <span className={`block text-base font-semibold ${showMoney ? "" : "blur-sm"}`}>{data.outcome ? data.outcome.toLocaleString() : 0} ₽</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
