export const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.width = '150px';
        tooltipEl.style.background = '#FFF';
        tooltipEl.style.borderRadius = '3px';
        tooltipEl.style.color = '#232927';
        tooltipEl.style.fontSize = '16px';
        tooltipEl.style.fontWeight = '700';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.zIndex = '9999';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';

        const div = document.createElement('div');
        div.style.margin = '0px';

        tooltipEl.appendChild(div);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
};

export const getOrCreateLine = (chart) => {
    let lineEl = chart.canvas.parentNode.querySelector('#line');

    if (!lineEl) {
        lineEl = document.createElement('span');
        lineEl.id = 'line'
        lineEl.style.background = '#2496FF';
        lineEl.style.display = 'block';
        lineEl.style.width = '2px';
        lineEl.style.height = '90%';
        lineEl.style.zIndex = '5';
        lineEl.style.borderRadius = '3px';
        lineEl.style.fontSize = '16px';
        lineEl.style.fontWeight = '700';
        lineEl.style.pointerEvents = 'none';
        lineEl.style.position = 'absolute';
        lineEl.style.top = '5%';
        lineEl.style.transform = 'translate(-50%, 0)';
        lineEl.style.transition = 'all .1s ease';

        chart.canvas.parentNode.appendChild(lineEl);
    }

    return lineEl;
};

export const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);
    const lineEl = getOrCreateLine(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        lineEl.style.display = 'none';
        return;
    }

    // Set Text
    if (tooltip.body) {
        const dayTitle = tooltip.title[0];
        const bodyLines = tooltip.body.map(b => b.lines);

        const divBody = document.createElement('div');
        divBody.style.textAlign = 'center';
        bodyLines.forEach((body, i) => {
            const colors = tooltip.labelColors[i];

            const span = document.createElement('span');
            span.style.background = colors.borderColor;
            span.style.borderRadius = '10px';
            span.style.marginRight = '8px';
            span.style.height = '10px';
            span.style.width = '10px';
            span.style.display = 'inline-block';

            const text = document.createTextNode(body + " ₽");

            const day = document.createElement('span');
            day.style.display = 'block';
            day.style.fontSize = '12px';
            day.textContent = dayTitle;

            divBody.appendChild(span);
            divBody.appendChild(text);
            divBody.appendChild(day);
        });

        const divRoot = tooltipEl.querySelector('div');

        // Remove old children
        while (divRoot.firstChild) {
            divRoot.firstChild.remove();
        }

        divRoot.appendChild(divBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY, height: heightMax, width: widthMax } = chart.canvas;

    lineEl.style.display = 'block';
    lineEl.style.left = positionX + tooltip.caretX + 'px';

    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = (heightMax / 2) + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    tooltipEl.style.zIndex = '9999';
};