import React, { useRef, useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import userService from '../../services/user.service';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function ChallengeTask({ challenge }) {
    const chartRef = useRef();
    const [chartData, setChartData] = useState({
        datasets: [],
    });
    const [step, setStep] = useState(1)
    const [maked, setMaked] = useState(challenge.maked)
    const [record, setRecord] = useState(challenge.record)

    useEffect(() => {
        const chart = chartRef.current;

        if (!chart) {
            return;
        }

        const chartData = {
            labels: ['Выполнено', 'Осталось'],
            datasets: [{
                borderWidth: 0,
                data: [maked, challenge.need - maked],
                backgroundColor: [
                    '#2496FF',
                    '#D9D9D9',
                ],
            }]
        };

        setChartData(chartData);
    }, [maked, challenge.need]);

    const makeProgress = async (plus) => {
        try {
            let makedNow = Number(plus ? step : -step)
            setMaked(maked + makedNow)
            if (maked + makedNow == challenge.need) {
                setRecord(record + 1)
            }
            if (maked == challenge.need && !plus) {
                setRecord(record - 1)
            }
            let data = {
                challenge_id: challenge.id,
                maked: makedNow
            }
            await userService.progressChallenge(data).then((result) => {
            })
        } catch (error) {
            console.log(error)
        }
    }

    const createLabel = (number, titles) => {
        const cases = [2, 0, 1, 1, 1, 2];
        return `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
    }

    const options = {
        scales: {
            y: {
                display: false
            },
            x: {
                display: false
            }
        },
        interaction: {
            mode: 'none',
            intersect: false,
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                display: false
            },
            rotateddd: {
                display: true
            }
        },
        elements: {
            point: {
                radius: 0,
                backgroundColor: '#2496FF',
                hoverRadius: 8
            }
        },
        tension: 0.2,
        rotation: 180,
        cutout: "85%",
        responsive: true,
    }

    return (
        <div className="relative overflow-hidden rounded-2xl bg-white">
            <div className="p-5">
                { challenge.need > 1 ? <div className="flex items-center justify-between">
                    <span className="bg-[#91BBE1] block text-2xl text-white text-center w-7 h-7 rounded-full leading-7 md:max-2xl:w-4 md:max-2xl:h-4 md:max-2xl:text-xl md:max-2xl:leading-4" onClick={() => makeProgress(false)}>—</span>
                    <div className="w-44 h-44 md:max-2xl:w-32 md:max-2xl:h-32 block relative">
                        <Doughnut ref={chartRef} type='doughnut' data={chartData} options={options} />
                        <div className="absolute flex flex-col top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
                            <span className="text-xl text-[#BFD8EE]">{maked} / {challenge.need}</span>
                        </div>
                    </div>
                    <span className="bg-[#91BBE1] block text-2xl text-white text-center w-7 h-7 rounded-full leading-7 md:max-2xl:w-4 md:max-2xl:h-4 md:max-2xl:text-xl md:max-2xl:leading-4" onClick={() => makeProgress(true)}>+</span>
                </div> : 
                <div>
                    <input type="checkbox" className="custom-checkbox" id={`challenge-make-${challenge.id}`} onChange={(event) => makeProgress(event.target.checked)} checked={maked == 1}></input>
                    <label htmlFor={`challenge-make-${challenge.id}`}>{challenge.title}</label>
                </div> }
                <div className="pt-3">
                    { challenge.need > 1 ? <span className="text-gray-black font-medium block">Цель: {challenge.title} </span> : "" }
                    <span className="text-gray-black font-normal block">Рекорд {record} {createLabel(record, ['день', 'дня', 'дней'])}</span>
                    { challenge.need > 1 ? 
                <div className="pt-3 flex">
                    <span className="text-light-gray">Шаг изменения</span>
                    <input type="text" className="border border-light-gray w-12 h-6 ml-2 rounded-md text-center text-light-gray" value={step} onChange={(event) => { setStep(event.target.value) }}></input>
                </div> : ""}
                </div>
            </div>
        </div>
    )
}
