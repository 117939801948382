import React, { Component } from 'react'
import ReactModal from 'react-modal';
import Select from 'react-select';
import userService from '../../services/user.service';

export default class AddTask extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false,

            selectedIcon: 'другое',
            text: '',
        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleAddTask = this.handleAddTask.bind(this);
    }

    handleAddTask() {
        const taskData = {
            icon: this.state.selectedIcon,
            text: this.state.text,
        };

        userService.addTask(taskData).then((value) => {
            this.props.fetchData()
            this.handleCloseModal()
        })
    }

    handleOpenModal() {
        this.setState({ 
            showModal: true,
            selectedIcon: 'другое',
            text: '',
        });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    handleSelectChange = (selectedOption) => {
        this.setState({ selectedIcon: selectedOption.value });
    };

    handleTextChange = (event) => {
        this.setState({ text: event.target.value });
    };

    customStylesModal = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    iconValues = ["другое", "школа", "дом", "работа"]
    iconValues = this.iconValues.map((value) => { return {value: value, label: <span className="flex items-center"><img src={`images/task/${value}.png`} className="w-4 h-4 mr-2"></img> {value}</span>} })

    render() {
        return (
            <div>
                <button className="bg-light-blue w-full h-12 block text-white font-medium rounded-lg mt-4" onClick={this.handleOpenModal}>Добавить задачу</button>

                <ReactModal
                    isOpen={this.state.showModal}
                    contentLabel="Minimal Modal Example"
                    style={this.customStylesModal}
                    overlayClassName="overlay__modal"
                    onRequestClose={this.handleCloseModal}
                    shouldCloseOnOverlayClick={true}
                    ariaHideApp={false}
                >
                    <div className="w-96">
                        <div className="flex justify-between items-center">
                            <span className="text-base font-semibold">Добавление задачи</span>
                            <span onClick={this.handleCloseModal}>
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 6.91L17.59 5.5L12 11.09L6.41 5.5L5 6.91L10.59 12.5L5 18.09L6.41 19.5L12 13.91L17.59 19.5L19 18.09L13.41 12.5L19 6.91Z" fill="#CCCCCC" />
                                </svg>
                            </span>
                        </div>
                        <div className="flex justify-between mt-3 items-center">
                            <span className="text-base">Иконка:</span>
                            <Select options={this.iconValues} defaultValue={this.iconValues[0]} onChange={this.handleSelectChange}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: '8px',
                                    }),
                                }} />
                        </div>
                        <div className="mt-3">
                            <textarea className="h-24 w-full bg-dark-white focus:outline-none rounded-lg px-5 pt-3 mt-3" placeholder="Текст задачи..." onChange={this.handleTextChange}></textarea>
                        </div>
                        <div className="mt-3 grid grid-cols-2 gap-2">
                            <button className="bg-light-blue w-full h-10 block text-white rounded-lg" onClick={this.handleAddTask}>Добавить</button>
                            <button className="bg-light-gray w-full h-10 block text-white rounded-lg" onClick={this.handleCloseModal}>Отмена</button>
                        </div>
                    </div>
                </ReactModal>
            </div>
        )
    }
}
