import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";

import AuthService from "./services/auth.service";

import Login from "./pages/login.component";
import Home from "./pages/home.component";

import EventBus from "./common/EventBus";
import { withRouter } from "./common/with-router";
import userService from "./services/user.service";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      currentUser: undefined,
      loadPage: true,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
      });
      AuthService.validUser().then(result => {
        if (!result) {
          this.setState({ currentUser: undefined, loadPage: false })
        } else {
          userService.updateBalance().then((value) => {
            this.setState({ loadPage: false })
          })
        }
      })
    } else {
      this.setState({ loadPage: false })
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      currentUser: undefined,
    });
  }

  render() {
    if (this.state.loadPage) {
      return (
        <div className="absolute h-screen w-screen bg-white">
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ">
            <img className="w-1/5 m-auto" src="https://otkritkis.com/wp-content/uploads/2022/07/gufdh.gif"></img>
          </div>
        </div>
      )
    }
    if (this.state.currentUser == undefined) {
      return (
        <Login />
      )
    }

    return (
      <div>
        {/* <div className="absolute h-screen w-20 md:max-2xl:w-10 border-r flex flex-col justify-center align-middle items-center gap-3 max-sm:hidden">
          <a className="block w-10 h-10 md:max-2xl:w-6 md:max-2xl:h-6 rounded-full bg-dark-white text-light-gray text-center leading-10 mx-auto p-2 md:max-2xl:p-1" href="/finance">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 14C2 10.2288 2 8.34315 3.17157 7.17157C4.34315 6 6.22876 6 10 6H14C17.7712 6 19.6569 6 20.8284 7.17157C22 8.34315 22 10.2288 22 14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14Z" stroke="#8E8E8E" strokeWidth="1.5" />
              <path d="M16 6C16 4.11438 16 3.17157 15.4142 2.58579C14.8284 2 13.8856 2 12 2C10.1144 2 9.17157 2 8.58579 2.58579C8 3.17157 8 4.11438 8 6" stroke="#8E8E8E" strokeWidth="1.5" />
              <path d="M12 17.3333C13.1046 17.3333 14 16.5871 14 15.6667C14 14.7462 13.1046 14 12 14C10.8954 14 10 13.2538 10 12.3333C10 11.4129 10.8954 10.6667 12 10.6667M12 17.3333C10.8954 17.3333 10 16.5871 10 15.6667M12 17.3333V18M12 10V10.6667M12 10.6667C13.1046 10.6667 14 11.4129 14 12.3333" stroke="#8E8E8E" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
          </a>
          <a className="block w-10 h-10 md:max-2xl:w-6 md:max-2xl:h-6 rounded-full bg-dark-white text-light-gray text-center leading-10 mx-auto p-2 md:max-2xl:p-1" href="/">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M21.4498 10.275L11.9998 3.1875L2.5498 10.275L2.9998 11.625H3.7498V20.25H20.2498V11.625H20.9998L21.4498 10.275ZM5.2498 18.75V10.125L11.9998 5.0625L18.7498 10.125V18.75H14.9999V14.3333L14.2499 13.5833H9.74988L8.99988 14.3333V18.75H5.2498ZM10.4999 18.75H13.4999V15.0833H10.4999V18.75Z" fill="#8E8E8E" />
            </svg>
          </a>
        </div> */}
        {/* <div className="absolute pl-16 md:max-2xl:pl-5 max-sm:pl-1 w-full"> */}
        <div className="absolute max-sm:pl-1 w-full">
          {/* <div className="absolute h-[50%] w-[50%] bg-[#f7fbff] rounded-full -left-20 -top-20 -z-10 blur-3xl"></div> */}
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
