import React, { useEffect, useState } from 'react'
import AddTask from './add_task';
import userService from '../../services/user.service';

export default function Tasks() {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const currentDate = new Date();

      const response = await userService.getTask((new Date(currentDate - currentDate.getTimezoneOffset() * 60000)).toISOString());
      const data = response.data.data;
      setData(data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchData()

    const interval = setInterval(fetchData, 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const makeCompleteTask = (id) => async (event) => {
    try {
      const updatedData = data.map(task => {
        if (task.id === id) {
          return {
            ...task,
            finished: event.target.checked
          };
        }
        return task;
      });

      setData(updatedData);

      await userService.completeTask({
        id: id,
        finished: event.target.checked
      });
    } catch (error) {
      console.error('Error send complete task:', error);
    }
  };

  return (
    <div className="relative">
      <span className="flex justify-left">
        <span className="text-lg font-medium mb-4 block text-gray-black">Задачи</span>

        <svg className="w-5 h-5 mt-1 ml-2" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => fetchData()}>
          <path d="M1.5 9C1.5 12.7275 4.5225 15.75 8.25 15.75C10.0425 15.75 11.76 15.045 13.05 13.8L11.925 12.675C10.9725 13.6875 9.645 14.25 8.25 14.25C3.57 14.25 1.23 8.595 4.5375 5.2875C7.845 1.98 13.5 4.3275 13.5 9H11.25L14.25 12H14.325L17.25 9H15C15 5.2725 11.9775 2.25 8.25 2.25C4.5225 2.25 1.5 5.2725 1.5 9Z" fill="#329DFF" />
        </svg>
      </span>

      <div className="relative overflow-hidden rounded-2xl  border-dashed border-2 border-color-dashed">
        <div className="p-5">
          <div className="grid gap-2">
            {data.length > 0 ? data.map(task => {
              return (
                <div className="flex justify-between items-center" id={`task-div-${task.id}`}>
                  <div className="flex items-center">
                    <div className="rounded-lg h-12 w-12 bg-dark-white p-2 mr-2">
                      <img src={`images/task/${task.icon}.png`} className="w-8 h-8" alt="task-icon"></img>
                    </div>
                    <div className="flex flex-col justify-center">
                      <span className={`block font-normal text-sm ${task.finished ? "line-through" : ""}`}>{task.text}</span>
                      <div className="flex items-center text-extra-low text-dark-blue">
                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 9.9C4.33305 9.9 3.21389 9.43643 2.38873 8.61127C1.56357 7.78611 1.1 6.66695 1.1 5.5C1.1 4.33305 1.56357 3.21389 2.38873 2.38873C3.21389 1.56357 4.33305 1.1 5.5 1.1C6.66695 1.1 7.78611 1.56357 8.61127 2.38873C9.43643 3.21389 9.9 4.33305 9.9 5.5C9.9 6.66695 9.43643 7.78611 8.61127 8.61127C7.78611 9.43643 6.66695 9.9 5.5 9.9ZM5.5 0C4.77773 0 4.06253 0.142262 3.39524 0.418663C2.72795 0.695063 2.12163 1.10019 1.61091 1.61091C0.579463 2.64236 0 4.04131 0 5.5C0 6.95869 0.579463 8.35764 1.61091 9.38909C2.12163 9.89981 2.72795 10.3049 3.39524 10.5813C4.06253 10.8577 4.77773 11 5.5 11C6.95869 11 8.35764 10.4205 9.38909 9.38909C10.4205 8.35764 11 6.95869 11 5.5C11 4.77773 10.8577 4.06253 10.5813 3.39524C10.3049 2.72795 9.89981 2.12163 9.38909 1.61091C8.87837 1.10019 8.27205 0.695063 7.60476 0.418663C6.93747 0.142262 6.22227 0 5.5 0ZM7.832 3.168C7.1885 2.519 6.3415 2.2 5.5 2.2V5.5L3.168 7.832C4.455 9.119 6.545 9.119 7.832 7.832C9.1245 6.545 9.1245 4.455 7.832 3.168Z" fill="#345069" /></svg>
                        <span className="pl-1">{task.created_at}</span></div>
                    </div>
                  </div>
                  <div>
                    <input type="checkbox" className="custom-checkbox" id={`task-${task.id}`} data-id={task.id} onChange={makeCompleteTask(task.id)} checked={task.finished}></input>
                    <label htmlFor={`task-${task.id}`}></label>
                  </div>
                </div>
              )
            }) : <div className="text-center text-light-gray">Пусто</div>}
          </div>
        </div>
      </div>
      <AddTask fetchData={fetchData} />
    </div>
  )
}
