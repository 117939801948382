import React, { useRef, useEffect, useState, useContext } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import userService from '../../services/user.service';
import { AppContext } from '../../AppContext';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Total() {
    const chartRef = useRef(null);
    const [chartData, setChartData] = useState({
        datasets: [],
    });
    const [lastUpdate, setLastUpdate] = useState(new Date())
    const [totalBalance, setTotalBalance] = useState(0);
    const { showMoney, setShowMoney } = useContext(AppContext);
    const { banks, setBanks } = useContext(AppContext);

    useEffect(() => {
        const chart = chartRef.current;

        if (!chart || !banks) {
            return;
        }

        const labels = banks.map(entry => entry.title);
        const values = banks.map(entry => entry.balance);

        const chartData = {
            labels: labels,
            datasets: [{
                borderWidth: 0,
                data: values,
                backgroundColor: [
                    '#E79191',
                    '#749CC1',
                    '#C6C379',
                    '#8FC9FF',
                    '#345069',
                ],
            }]
        };

        setTotalBalance(values.reduce((partialSum, a) => partialSum + a, 0));
        setChartData(chartData);
        setLastUpdate(new Date())
    }, [banks]);

    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        elements: {
            point: {
                radius: 0,
                backgroundColor: '#2496FF',
                hoverRadius: 8
            }
        },
        tension: 0.2,
        rotation: 180,
        cutout: "85%",
        responsive: true,
    }

    return (
        <div className="relative">
            <span className="text-lg font-medium mb-4 block text-gray-black">Капитал</span>
            <div className="relative overflow-hidden rounded-2xl bg-white">
                <div className="w-44 h-44 m-auto my-4">
                    <Doughnut ref={chartRef} type='doughnut' data={chartData} options={options} />
                    <div className="absolute flex flex-col top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
                        <span className={`text-xl font-bold md:max-2xl:text-xl ${showMoney ? "" : "blur-[8px]"}`}>{totalBalance.toLocaleString()} ₽</span>
                        <span className="text-sm text-light-gray md:max-2xl:text-low">{lastUpdate.toLocaleString()}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
