import React, { Component } from 'react'
import BankShort from "./bank_short.component";
import userService from '../../services/user.service';
import { AppContext } from '../../AppContext';

export default class MyBankShort extends Component {
  constructor() {
    super();
    this.state = {
      updating: false
    }
  }

  fetchUpdateBalance = async (setBanks) => {
    this.setState({ updating: true })
    try {
      await userService.updateBalance().then((result) => {
        userService.getBanks().then((banks) => {
          setBanks(banks.data.data)
          this.setState({ updating: false })
        })
      })
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    if (this.state.updating) {
      return (
        <div>
          <div className="mt-4">
            <span className="text-lg font-medium mb-4 block text-gray-black">Мои счета</span>
            <div className="relative overflow-hidden rounded-2xl bg-white">
              <img className="w-20 m-auto py-5" src="https://otkritkis.com/wp-content/uploads/2022/07/gufdh.gif" alt="loader"></img>
            </div>
          </div>
        </div>
      )
    }

    return (
      <AppContext.Consumer>
        {({ showMoney, setShowMoney, banks, setBanks }) => (
          <div>
            <div className="mt-4">
              <div className="flex justify-between mb-4">
                <span className="font-medium text-lg text-gray-black">Мои счета</span>
                <span className="flex gap-1">
                  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setShowMoney(!showMoney)}>
                    <path d="M3.27489 15.2957C2.42496 14.1915 2 13.6394 2 12C2 10.3606 2.42496 9.80853 3.27489 8.70433C4.97196 6.49956 7.81811 4 12 4C16.1819 4 19.028 6.49956 20.7251 8.70433C21.575 9.80853 22 10.3606 22 12C22 13.6394 21.575 14.1915 20.7251 15.2957C19.028 17.5004 16.1819 20 12 20C7.81811 20 4.97196 17.5004 3.27489 15.2957Z" stroke="#329DFF" strokeWidth="1.5" />
                    <path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="#329DFF" strokeWidth="1.5" />
                  </svg>
                  <svg className="w-5 h-5" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => this.fetchUpdateBalance(setBanks)}>
                    <path d="M1.5 9C1.5 12.7275 4.5225 15.75 8.25 15.75C10.0425 15.75 11.76 15.045 13.05 13.8L11.925 12.675C10.9725 13.6875 9.645 14.25 8.25 14.25C3.57 14.25 1.23 8.595 4.5375 5.2875C7.845 1.98 13.5 4.3275 13.5 9H11.25L14.25 12H14.325L17.25 9H15C15 5.2725 11.9775 2.25 8.25 2.25C4.5225 2.25 1.5 5.2725 1.5 9Z" fill="#329DFF" />
                  </svg>
                </span>

              </div>
              <div className="grid grid-rows-3 gap-1">
                {banks ? banks.map((bank) => {
                  return (
                    <BankShort key={bank.id} title={bank.title} wallet={bank.wallet} balance={bank.balance} lastUpdate={bank.time} />
                  )
                }) : ""}
              </div>
            </div>
          </div>
        )}
      </AppContext.Consumer>
    )
  }
}
