import React, { useRef, useEffect, useState, useContext } from 'react';
import userService from '../../services/user.service';
import { AppContext } from '../../AppContext';

export default function Rates() {
  const [rates, setRates] = useState([])

  const fetchData = async (loader) => {
    if (loader) {
      setRates([])
    }
    try {
      await userService.getRates().then(result => {
        setRates(result.data.data)
      })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchData(true)

    const interval = setInterval(() => fetchData(false), 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  if (rates.length == 0) {
    return (
      <div className="relative mt-4">
        <span className="flex justify-left">
          <span className="text-lg font-medium mb-4 block text-gray-black">Курсы валют</span>
        </span>
        <div className="relative overflow-hidden rounded-2xl bg-white">
          <img className="w-20 m-auto py-5" src="https://otkritkis.com/wp-content/uploads/2022/07/gufdh.gif" alt="loader"></img>
        </div>
      </div>
    )
  }

  return (
    // md:max-2xl
    <div className="relative mt-4">
      <span className="flex justify-left">
        <span className="text-lg font-medium mb-4 block text-gray-black">Курсы валют</span>

        <svg className="w-5 h-5 mt-1 ml-2" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => fetchData(true)}>
          <path d="M1.5 9C1.5 12.7275 4.5225 15.75 8.25 15.75C10.0425 15.75 11.76 15.045 13.05 13.8L11.925 12.675C10.9725 13.6875 9.645 14.25 8.25 14.25C3.57 14.25 1.23 8.595 4.5375 5.2875C7.845 1.98 13.5 4.3275 13.5 9H11.25L14.25 12H14.325L17.25 9H15C15 5.2725 11.9775 2.25 8.25 2.25C4.5225 2.25 1.5 5.2725 1.5 9Z" fill="#329DFF" />
        </svg>
      </span>

      <div className="relative overflow-hidden rounded-2xl bg-white">
        <div className="p-5 flex justify-between">
          <div className="text-center">
            <span className="text-light-gray block">USD</span>
            <span className="text-gray-black font-medium">{(Math.round(rates.usd * 100) / 100).toLocaleString()} ₽</span>
          </div>
          <div className="text-center">
            <span className="text-light-gray block">BTC</span>
            <span className="text-gray-black font-medium">{(Math.round(rates.btc)).toLocaleString()} $</span>
          </div>
          <div className="text-center">
            <span className="text-light-gray block">EUR</span>
            <span className="text-gray-black font-medium">{(Math.round(rates.eur * 100) / 100).toLocaleString()} ₽</span>
          </div>
        </div>
      </div>
    </div>
  )
}
