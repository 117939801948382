import { createContext, useEffect, useState } from 'react';
import userService from './services/user.service';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [showMoney, setShowMoney] = useState(false);
  const [banks, setBanks] = useState([]);
  const [transactions, setTransactions] = useState([]);
  
  const updateData = () => {
    console.log("updated")
    try {
      userService.getBanks().then((response) => {
        setBanks(response.data.data)
      });
      userService.getTransactions(6).then((response) => {
        setTransactions(response.data.data)
      })
    } catch (error) {
      console.error('Error fetching banks data:', error);
    }
  }

  useEffect(() => {
    updateData()
    const interval = setInterval(updateData, 60 * 1000)

    return () => clearInterval(interval);
  }, [])

  return (
    <AppContext.Provider value={{ showMoney, setShowMoney, banks, setBanks, transactions, setTransactions }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
