import React, { useState, useRef, useEffect } from 'react'

export default function DropDownCalendar({title, child}) {
    const [isBlockVisible, setBlockVisibility] = useState(false);
    const [blockHeight, setBlockHeight] = useState(0);
    const blockRef = useRef(null);

    useEffect(() => {
        setBlockHeight(isBlockVisible ? blockRef.current.scrollHeight : 0);
    }, [isBlockVisible]);

    const handleToggleBlock = () => {
        setBlockVisibility(!isBlockVisible);
    };

    return (
        <div className="px-4 pr-9 mb-3">
            <div className="flex justify-between items-center">
                <span className="font-semibold text-dark-gray">{title}</span>
                <span onClick={handleToggleBlock} className={`${isBlockVisible ? 'open-symbol' : ''}`}>
                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.31859 0.245127C6.98529 -0.0817085 6.44491 -0.0817086 6.1116 0.245127L0.680151 5.57129C0.346851 5.89812 0.346851 6.42804 0.680152 6.75487C1.01345 7.08171 1.55384 7.08171 1.88714 6.75487L6.7151 2.02051L11.5431 6.75487C11.8764 7.08171 12.4167 7.08171 12.7501 6.75487C13.0834 6.42803 13.0834 5.89812 12.7501 5.57128L7.31859 0.245127ZM7.56857 1.67385L7.56857 0.836925L5.86163 0.836924L5.86163 1.67385L7.56857 1.67385Z" fill="#2496FF" />
                    </svg>
                </span>
            </div>
            <div className={`block-hidden ${isBlockVisible ? 'visible' : ''}`} style={{ height: blockHeight }} ref={blockRef}>
                <div className="pt-1">
                    {child}
                </div>
            </div>
        </div>
    )
}
