import React, { useEffect, useState } from 'react'

import {Calendar as CalendarPlugin} from 'react-calendar';
import CalendarValue from "./calendar-value.component";
import DropDownCalendar from "./drop-down-calendar.component";
import userService from '../../services/user.service';

export default function Calendar() {
    const [today, setToday] = useState(new Date());
    const [date, setDate] = useState(new Date());
    const [mental, setMental] = useState({});
    const [eyeDates, setEyeDates] = useState([]);

    useEffect(() => {
        if (date <= today) {
            userService.getMental((new Date(date - date.getTimezoneOffset() * 60000)).toISOString()).then(result => {
                setMental(result.data.data)
            })
        }
        
        userService.getMentalEye().then(result => {
            setEyeDates(result.data.data)
        })

        setToday(new Date());
    }, [date])

    return (
        <div className="pt-3">
            <div className="rounded-2xl relative bg-white">
                <span className="text-light-gray font-medium text-sm block px-4 pt-4">{date.getDate()} {date.toLocaleString('default', { 'month': 'long' })}</span>
                {date < today ? <DropDownCalendar title={"Состояние"} child={
                <div className="flex flex-row items-center justify-start gap-1">
                    <img src={`images/faces/${mental.rate}.png`} className="h-8 w-8"></img>
                    {mental.eye ? <span className="p-1 bg-red-800 text-extra-low font-bold text-white rounded-lg whitespace-nowrap">Тройничный нерв</span> : ""}
                    {mental.production ? <span className="p-1 bg-green-600 text-extra-low font-bold text-white rounded-lg">Продуктивный</span> : ""}
                    {mental.sleeply ? <span className="p-1 bg-blue-500 text-extra-low font-bold text-white rounded-lg">Сонливость</span> : ""}
                </div>} /> : ""}
                {/* <DropDownCalendar title={"Расходы"} />
                <DropDownCalendar title={"История задач"} /> */}
                <CalendarPlugin
                    prevLabel={<svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.245127 6.6698C-0.0817082 7.01451 -0.0817083 7.57339 0.245127 7.9181L5.57129 13.5354C5.89812 13.8801 6.42804 13.8801 6.75487 13.5354C7.08171 13.1907 7.08171 12.6318 6.75487 12.2871L2.02051 7.29395L6.75487 2.30074C7.08171 1.95605 7.08171 1.39723 6.75487 1.05246C6.42803 0.707774 5.89812 0.707774 5.57128 1.05246L0.245127 6.6698ZM1.67385 6.41128L0.836925 6.41128L0.836924 8.17663L1.67385 8.17663L1.67385 6.41128Z" fill="#717171" /></svg>}
                    nextLabel={<svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.75487 7.91809C7.08171 7.57338 7.08171 7.0145 6.75487 6.66979L1.42871 1.05248C1.10188 0.707769 0.571965 0.707769 0.245129 1.05248C-0.0817099 1.39718 -0.08171 1.95606 0.24513 2.30077L4.97949 7.29394L0.24513 12.2872C-0.0817094 12.6318 -0.0817087 13.1907 0.24513 13.5354C0.571971 13.8801 1.10188 13.8801 1.42872 13.5354L6.75487 7.91809ZM5.32615 8.17662L6.16308 8.17662L6.16308 6.41126L5.32615 6.41126L5.32615 8.17662Z" fill="#717171" /></svg>}
                    prev2Label={null}
                    next2Label={null}
                    tileContent={({ activeStartDate, date, view }) => (
                        view === 'month' && <CalendarValue eye={eyeDates.includes(`${date.getDate()}.${date.getMonth() + 1}`)} day={date.getDate()} today={date.toDateString() == today.toDateString()} />
                    )}
                    onChange={setDate}
                />
            </div>
        </div>
    )
}
