import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import Weather from "./components/weather.component";
import Mental from "./components/mental.component";
import Tasks from "./components/tasks.component";
import "./components/calendar.css";
import Graphs from "./components/graphs.component";
import Challenge from "./components/challenge.component";
import Total from "./components/total.component";
import StatMonth from "./components/stat_month.component";
import Calendar from "./components/calendar.component";
import MyBank from "./components/my_bank.component";
import Header from "./components/header.component";
import Transactions from "./components/transactions";
import AddTransaction from "./components/add_transaction";
import MyBankShort from "./components/my_bank_short.component";
import userService from "../services/user.service";
import Rates from "./components/rates.component";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      userReady: false,
      currentUser: { username: "" },
      currentDate: new Date(),
      showMoney: true,
    };
  }

  onChange = (selectedDate) => {
    this.setState({ currentDate: selectedDate });
  };

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (currentUser) {
      AuthService.validUser().then(result => {
        if (!result) {
          this.setState({ redirect: "/login" })
        }
      })
    }

    if (!currentUser) this.setState({ redirect: "/login" });
    this.setState({ currentUser: currentUser, userReady: true })
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }

    return (
      <div className="h-screen flex flex-row space-x-12 px-10 pt-5 max-sm:flex-col max-sm:space-x-0">
        <div className="flex flex-col basis-[20%]">
          <Header />
          <div className="h-full overflow-hidden overflow-y-scroll">
            <MyBankShort />
            <AddTransaction />
            <Calendar />
          </div>
        </div>
        <div className="basis-[55%] sm:h-full sm:overflow-hidden sm:overflow-y-scroll max-sm:order-1">
          {/* <MyBank /> */}
          <div className="flex space-x-12 md:max-xl:block md:max-xl:space-x-0 max-sm:flex-col max-sm:space-x-0 md:max-2xl:mt-2 ">
            <div className="basis-1/3 max-sm:order-5">
              <div className="grid gap-4">
                <Weather />
                <Rates />
                <Mental />
                <Tasks />
              </div>
            </div>
            <div className="basis-2/3 md:max-xl:mt-2 max-sm:order-1">
              <div className="grid gap-4 max-sm:grid-cols-1 max-sm:mt-2">
                <Graphs />
                <Challenge />
              </div>
            </div>
          </div>
        </div>
        <div className="basis-[25%] max-sm:mt-2 max-sm:order-2 sm:h-full sm:overflow-hidden sm:overflow-y-scroll">
          <Total />
          <StatMonth />
          <Transactions />
        </div>
      </div>
    );
  }
}
