import React, { Component } from 'react'
import { AppContext } from '../../AppContext';

export default class Transactions extends Component {
  render() {
    return (
      <AppContext.Consumer>
        {({ showMoney, setShowMoney, banks, setBanks, transactions, setTransactions }) => (
          <div className="relative mt-4">
            <span className="text-lg font-medium mb-4 block text-gray-black">Последние транзакции</span>
            {/* лютый костыль пизда зайца */}
            <span className="text-green-600 text-red-600"></span>
            <div className="overflow-hidden overflow-y-scroll">
              <div className="flex flex-col gap-2">
                {transactions.map((transaction) => {
                  return (
                    <div className="flex relative overflow-hidden rounded-2xl bg-white p-3 justify-between md:max-2xl:flex-col" key={`transaction-${transaction.id}`}>
                      <div className="flex items-center">
                        <img src={`images/${transaction.bank}.png`} className="w-11 h-11 rounded-lg" alt="bank-icon"></img>
                        <div className="flex pl-4 flex-col">
                          <span className={`text-${transaction.amount > 0 ? 'green' : 'red'}-600 font-semibold block`}>{transaction.amount.toLocaleString()} ₽</span>
                          <span className="overflow-hidden text-gray text-sm">{transaction.comment}</span>
                        </div>
                      </div>
                      <div className="md:max-2xl:hidden flex items-center">
                        <span className="text-light-gray text-low">{transaction.time}</span>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </AppContext.Consumer>
    )
  }
}
