import React, { useContext } from 'react'
import { AppContext } from '../../AppContext';

export default function BankShort({ title, wallet, balance, lastUpdate }) {
    const { showMoney, setShowMoney } = useContext(AppContext);

    return (
        <div className="rounded-md p-2.5 relative bg-white whitespace-nowrap overflow-hidden">
            <div className="flex items-center justify-between">
                <div className="flex items-center justify-normal">
                    <img src={`images/${title}.png`} className="w-8 h-8 rounded-lg"></img>
                    <span className="pl-1.5 text-sm font-medium">{title.charAt(0).toUpperCase() + title.slice(1)}</span>
                    { wallet.length > 10 ? <span className="pl-1.5 text-extra-low font-normal text-dark-gray">**** {wallet.substr(wallet.length - 4)}</span> : '' }
                </div>
                <div className="text-right">
                    <span className={`md:max-2xl:block font-bold text-base text-dark-blue ${showMoney ? "" : "blur-sm"}`}>{balance.toLocaleString()} ₽</span>
                    <span className="font-normal text-low text-light-gray pl-2">{lastUpdate}</span>
                </div>
            </div>
        </div>
    )
}
