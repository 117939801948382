import React from 'react'

export default function Bank({ title, wallet, balance }) {
    return (
        <div className="rounded-md p-4 relative bg-white whitespace-nowrap overflow-hidden">
            <div className="flex flex-col">
                <div className="flex items-center">
                    <img src={`images/${title}.png`} className="w-8 h-8 rounded-lg"></img>
                    <span className="pl-2 font-bold text-xl text-black">{balance.toLocaleString()} ₽</span>
                    <span className="font-normal text-low text-light-gray pl-2">— 03:19 03.01</span>
                </div>
                <div className="flex items-center align-middle pt-2">
                    <span className="text-base text-black font-medium">{title.charAt(0).toUpperCase() + title.slice(1)}</span>
                    { wallet.length > 10 ? <span className="pl-1.5 text-low font-normal text-dark-gray">**** {wallet.substr(wallet.length - 4)}</span> : '' }
                </div>
            </div>
        </div>
    )
}
