import React, { useState, useEffect, useRef } from 'react'
import PoutingFace from './images/pouting_face.png';
import userService from '../../services/user.service';

export default function Mental() {
    const [isBlockMentalVisible, setBlockMentalVisibility] = useState(false);
    const [blockHeight, setBlockMentalHeight] = useState(0);
    const [rateMental, setRateMental] = useState(3);
    const [eye, setEye] = useState(false);
    const [production, setProduction] = useState(false);
    const [sleeply, setSleeply] = useState(false);
    const blockMentalRef = useRef(null);

    useEffect(() => {
        fetchData()
        setBlockMentalHeight(isBlockMentalVisible ? blockMentalRef.current.scrollHeight : 0);
    }, [isBlockMentalVisible]);

    const fetchData = async () => {
        try {
          const currentDate = new Date();
    
          const response = await userService.getMental((new Date(currentDate - currentDate.getTimezoneOffset() * 60000)).toISOString());
          const data = response.data.data;
          setEye(data.eye ? true : false)
          setProduction(data.production)
          setSleeply(data.sleeply)
          setRateMental(data.rate)
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    }

    const handleToggleBlockMental = () => {
        setBlockMentalVisibility(!isBlockMentalVisible);
    };

    const handleOnChangeEmoji = (event) => {
        setRateMental(event.target.value)
    }

    const saveMental = () => {
        let data = {
            rate: Number(rateMental),
            eye: eye,
            production: production,
            sleeply: sleeply
        }
        userService.saveMental(data)
    }

    return (
        <div className="relative">
            <span className="text-lg font-medium mb-4 block text-gray-black">Мое состояние</span>
            <div className="relative overflow-hidden rounded-2xl bg-white py-4 px-7">
                <div className="m-auto h-20 w-20 p-4 rounded-full bg-gradient-to-b from-[#C1E1FF] to-white">
                    <img src={`images/faces/${rateMental}.png`} className="h-12 w-12"></img>
                </div>

                <input
                    type="range"
                    className="w-full appearance-none relative overflow-hidden h-[10px] cursor-pointer mt-3"
                    id="customRange1"
                    min="1"
                    max="5"
                    step="1"
                    value={rateMental}
                    onChange={handleOnChangeEmoji}
                />

                <div className="flex justify-center text-light-blue text-sm font-semibold m-auto items-center" onClick={handleToggleBlockMental}>
                    <span className={`${isBlockMentalVisible ? 'open-symbol' : ''}`}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.6665 6.66667L7.99984 10L11.3332 6.66667H4.6665Z" fill="#2496FF" />
                        </svg>
                    </span>
                    <span>
                        Добавить метку
                    </span>
                </div>

                <div className={`mt-2 block-hidden ${isBlockMentalVisible ? 'visible' : ''}`} style={{ height: blockHeight }} ref={blockMentalRef}>
                    <hr className="m-auto"></hr>
                    <div className="flex my-2 gap-1 flex-wrap">
                        <span className={`p-2 bg-red-800 text-extra-low font-bold text-white rounded-lg whitespace-nowrap ${eye ? "border border-black border-2" : ""}`} onClick={() => setEye(!eye)}>Тройничный нерв</span>
                        <span className={`p-2 bg-green-600 text-extra-low font-bold text-white rounded-lg ${production ? "border border-black border-2" : ""}`} onClick={() => setProduction(!production)}>Продуктивный</span>
                        <span className={`p-2 bg-blue-500 text-extra-low font-bold text-white rounded-lg ${sleeply ? "border border-black border-2" : ""}`} onClick={() => setSleeply(!sleeply)}>Сонливость</span>
                    </div>
                    <hr className="m-auto"></hr>
                    <div className="my-2">
                        <button className="h-9 bg-light-blue w-full rounded-lg text-white font-normal text-low" onClick={saveMental}>Применить</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
