import axios from 'axios';
import authHeader from './auth-header';

const API_URL = "https://life.alegor.space/api/v1/";

class UserService {
  getWeather() {
    return axios.get(API_URL + 'weather', { headers: authHeader() });
  }
  getLight() {
    return axios.get(API_URL + 'light', { headers: authHeader() });
  }
  getBanks() {
    return axios.get(API_URL + 'bank/list', { headers: authHeader() });
  }
  getUser() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }
  getStatMonth() {
    return axios.get(API_URL + 'transaction/month', { headers: authHeader() });
  }
  getTransactions(limit) {
    return axios.post(API_URL + 'transaction/get', { limit: limit }, { headers: authHeader() });
  }
  addTransaction(data) {
    return axios.post(API_URL + 'transaction/add', data, { headers: authHeader() });
  }
  addTask(data) {
    return axios.post(API_URL + 'task/add', data, { headers: authHeader() });
  }
  getTask(day) {
    return axios.post(API_URL + 'task/get', { date: day }, { headers: authHeader() });
  }
  completeTask(data) {
    return axios.post(API_URL + 'task/complete', data, { headers: authHeader() });
  }
  getChartValues(days, bank) {
    return axios.post(API_URL + 'bank/chart', { days: days + 1, bank: bank }, { headers: authHeader() });
  }
  updateBalance() {
    return axios.post(API_URL + 'bank/update', {}, { headers: authHeader() });
  }
  getChallenges() {
    return axios.get(API_URL + 'challenge/get', { headers: authHeader() });
  }
  progressChallenge(data) {
    return axios.post(API_URL + 'challenge/progress', data, { headers: authHeader() });
  }
  getMental(day) {
    return axios.post(API_URL + 'mental/get', { day: day }, { headers: authHeader() });
  }
  getMentalEye() {
    return axios.get(API_URL + 'mental/eye', { headers: authHeader() });
  }
  saveMental(data) {
    return axios.post(API_URL + 'mental/save', data, { headers: authHeader() });
  }
  getRates() {
    return axios.get(API_URL + 'rates', { headers: authHeader() });
  }
}

export default new UserService();
