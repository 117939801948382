import React, { useRef, useEffect, useState, useContext } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    Filler,
    registerables
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { externalTooltipHandler } from './graphs.tooltip';
import userService from '../../services/user.service';
import { AppContext } from '../../AppContext';

ChartJS.register(...registerables);

function createGradient(ctx, area) {
    const colorStart = '#18BFEF';
    const colorEnd = 'rgba(7, 136, 255, 0.00)';

    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

    gradient.addColorStop(0, colorEnd);
    gradient.addColorStop(1, colorStart);

    return gradient;
}

const Graphs = () => {
    const chartRef = useRef(null);
    const [chartData, setChartData] = useState({
        datasets: [],
    });
    const [changePercent, setChangePercent] = useState(0);
    const [currentDayView, setCurrentDayView] = useState(365);
    const [currentBankView, setCurrentBankView] = useState('');

    const { showMoney } = useContext(AppContext);
    const { banks } = useContext(AppContext);

    const changeViewDays = (days) => {
        setCurrentDayView(days)
    }

    const changeViewBank = (bank) => {
        setCurrentBankView(bank)
    }

    useEffect(() => {
        const fetchData = async () => {
            const chart = chartRef.current;
    
            if (!chart) {
                return;
            }
    
            try {
                const response = await userService.getChartValues(currentDayView, currentBankView);
                const data = response.data.data;
    
                const labels = Object.keys(data).slice(1);
                const values = Object.values(data).slice(1);
    
                const updatedChartData = {
                    labels: labels,
                    datasets: [{
                        borderColor: '#2496FF',
                        borderWidth: 2,
                        backgroundColor: createGradient(chart.ctx, chart.chartArea),
                        label: '',
                        fill: 'start',
                        data: values,
                    }],
                };
    
                setChartData(updatedChartData);
                setChangePercent(Math.round(values[values.length - 1] - values[0]));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        
        fetchData();
    }, [currentBankView, currentDayView, banks]);

    const options = {
        scales: {
            y: {
                display: false
            },
            x: {
                display: false
            }
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false,
                position: 'nearest',
                external: externalTooltipHandler,
            }
        },
        elements: {
            point: {
                radius: 0,
                backgroundColor: '#2496FF',
                hoverRadius: 8
            }
        },
        tension: 0.2
    }

    return (
        <div className="relative">
            <span className="flex justify-left">
                <span className="text-lg font-medium mb-4 block text-gray-black">Аналитика</span>
            </span>
            <div className="relative rounded-2xl bg-white">
                <div className="p-4">
                    <div className="flex justify-between">
                        <span className="block text-2xl">Финансы</span>
                        <div className={`text-2xl text-${changePercent > 0 ? 'green' : 'red'}-600 flex items-center ${showMoney ? "" : "blur-[5px]"}`}>
                            {
                                changePercent > 0 ? (
                                    <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 17L17 7M17 7H8M17 7V16" stroke="rgb(22, 163, 74)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                ) : (
                                    <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 7L17 17M17 17V7M17 17H7" stroke="rgb(220, 38, 38)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                )
                            }
                            {changePercent.toLocaleString()} ₽
                        </div>
                    </div>
                    <div className="flex justify-between max-sm:flex-col max-sm:gap-1">
                        <div className="flex gap-2">
                            <button className={`px-2 h-8 ${currentBankView === '' ? 'bg-light-blue text-white' : 'border-light-gray text-gray-black'} border rounded-xl font-medium text-xs`} onClick={() => changeViewBank('')}>ALL</button>
                            <button className={`px-2 h-8 ${currentBankView === 'tinkoff' ? 'bg-light-blue text-white' : 'border-light-gray text-gray-black'} border rounded-xl font-medium text-xs`} onClick={() => changeViewBank('tinkoff')}>Тинькофф</button>
                            <button className={`px-2 h-8 ${currentBankView === 'bitcoin' ? 'bg-light-blue text-white' : 'border-light-gray text-gray-black'} border rounded-xl font-medium text-xs`} onClick={() => changeViewBank('bitcoin')}>Биткоин</button>
                        </div>
                        <div className="flex gap-2">
                            <button className={`px-2 h-8 ${currentDayView === 365 ? 'bg-light-blue text-white' : 'border-light-gray text-gray-black'} border rounded-xl font-medium text-xs`} onClick={() => changeViewDays(365)}>ALL</button>
                            <button className={`px-2 h-8 ${currentDayView === 30 ? 'bg-light-blue text-white' : 'border-light-gray text-gray-black'} border rounded-xl font-medium text-xs`} onClick={() => changeViewDays(30)}>Месяц</button>
                            <button className={`px-2 h-8 ${currentDayView === 7 ? 'bg-light-blue text-white' : 'border-light-gray text-gray-black'} border rounded-xl font-medium text-xs`} onClick={() => changeViewDays(7)}>Неделя</button>
                        </div>
                    </div>
                </div>
                <div className="p-3 relative graph-width">
                    <Chart ref={chartRef} type='line' data={chartData} options={options} />
                </div>
            </div>
        </div>
    )
}

export default Graphs;