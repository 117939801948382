import React, { Component } from 'react'
import Bank from "./bank.component";
import ReactModal from 'react-modal';
import Select from 'react-select';
import userService from '../../services/user.service';

export default class MyBank extends Component {
  constructor() {
    super();
    this.state = {
      banks: []
    };
  }

  componentDidMount() {
    this.fetchBanksData()
  }

  fetchBanksData = async () => {
    try {
      const response = await userService.getBanks();
      this.setState({ banks: response.data.data });
      await userService.updateBalance();
    } catch (error) {
      console.error('Error fetching weather:', error);
    }
  };

  render() {
    return (
      <div className="mb-5">
        <div className="flex justify-between mb-4">
          <span className="font-medium text-lg text-gray-black">Мои счета</span>
        </div>
        <div className="grid grid-rows-2 grid-cols-3 gap-3">
          {this.state.banks.map((bank) => {
            return (
              <Bank title={bank.title} wallet={bank.wallet} balance={bank.balance} />
            )
          })}
        </div>
      </div>
    )
  }
}
