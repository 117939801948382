import React from 'react'

export default function CalendarValue({eye, day, today}) {
  return (
    <div className="flex justify-center">
      { today ? <span className={`w-6 h-6 rounded-full bg-light-blue absolute top-1/2 -translate-y-1/2 leading-6 text-white`}>{day}</span> : ""}
      { !today && eye ? <span className={`w-6 h-6 rounded-full bg-red-600 absolute top-1/2 -translate-y-1/2 leading-6 text-white`}>{day}</span> : ""}
    </div>
  );
}
