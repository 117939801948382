import React from 'react'

export default function Header() {
    return (
        <div className="grid place-items-center rounded-lg">
            <img src="images/avatar.jpg" className="rounded-full w-36 h-36 mt-2"></img>
            <span className="pt-3 font-medium text-base">Алексей Г.</span>
        </div>
    )
}
