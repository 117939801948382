import React, { useEffect, useState } from 'react'
import ChallengeTask from './challenge_task.component'
import userService from '../../services/user.service';

export default function Challenge() {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    setData([])
    try {
      const response = await userService.getChallenges();
      const data = response.data.data;
      setData(data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchData()
  }, []);

  return (
    <div className="relative">
      <span className="flex justify-left">
        <span className="text-lg font-medium mb-4 block text-gray-black">Мои цели</span>
      </span>

      <div className="grid md:xl:grid-cols-2 gap-5">
        {data.map(challenge => {
          return (<ChallengeTask challenge={challenge} key={challenge.id} />)
        })}
      </div>
    </div>
  )
}
